import { AnalyticsClient, SignupState } from '../../types'
import { toPascal } from '../stringHelpers'

export interface CdmEventDetail {
  version?: string
  activity?: string
  screenPath?: string
  screenName: string
  controlName?: string
  controlDetail?: string
  action: 'ScreenDisplayed' | 'ControlButtonClicked' | 'ControlHyperLinkClicked'
}

export interface CdmEvent {
  version: string
  dateTime: string
  eventDetailType: string
  eventCategory: string
  eventDetail: CdmEventDetail
}

export type PublishCdmEvents = (data: CdmEvent[]) => unknown

export class CdmSmbAnalyticsClient implements AnalyticsClient {
  pathname: string
  baseScreenPath: string
  publishCdmEvents: PublishCdmEvents

  constructor(signupState: SignupState) {
    const {
      analytics,
      signupSession: { isODFlow, analyticsBaseScreenPathName }
    } = signupState

    const baseScreenPath =
      analyticsBaseScreenPathName ||
      (isODFlow ? '/ReactSmbInstantInkSignup/' : '/VulcanLoader/')
    this.pathname = ''
    this.baseScreenPath = baseScreenPath
    this.publishCdmEvents = analytics?.publishCdmEvents
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateState(signupState: SignupState) {
    // nothing to do here
  }

  publishClickEvent({
    eventTarget,
    linkId,
    linkPlacement
  }: {
    eventTarget?: EventTarget
    linkId?: string
    linkPlacement?: string
  }) {
    if (eventTarget instanceof Element) {
      const element = eventTarget
      const nodeName = element.nodeName.toLowerCase()
      if (['a', 'button'].includes(nodeName)) {
        const screenPath = element['screenName']
          ? this.getModalScreenPath()
          : this.baseScreenPath
        const screenName = toPascal(
          element['screenName'] || this.getDefaultScreenName()
        )
        const controlName = toPascal(
          element.getAttribute('data-analyticsid') ||
            element.getAttribute('data-testid') ||
            element.textContent
        )
        const controlDetail = element['controlDetail']
        const action =
          nodeName === 'a' ? 'ControlHyperLinkClicked' : 'ControlButtonClicked'
        if (controlName) {
          this.publishEvent({
            screenPath,
            screenName,
            controlName,
            controlDetail,
            action
          })
        }
      }
      return
    }

    this.publishLink(linkId, linkPlacement)
  }

  publishDisplayEvent({
    pathname,
    screenName
  }: {
    pathname?: string
    screenName?: string
  }) {
    if (pathname) {
      this.pathname = pathname
    }
    this.publishEvent({
      screenPath: screenName ? this.getModalScreenPath() : this.baseScreenPath,
      screenName: toPascal(screenName || this.getDefaultScreenName()),
      action: 'ScreenDisplayed'
    })
  }

  publishCustomEvent() {
    return
  }

  private getDefaultScreenName() {
    return toPascal(this.pathname.substring(this.pathname.lastIndexOf('/') + 1))
  }

  private getModalScreenPath() {
    const screenName = this.getDefaultScreenName()
    return `${this.baseScreenPath}${screenName ? screenName + '/' : ''}`
  }

  private publishLink(linkId?: string, linkPlacement?: string) {
    if (linkId && linkPlacement) {
      this.publishEvent({
        screenPath: this.baseScreenPath,
        screenName: toPascal(linkPlacement),
        controlName: linkId,
        action: 'ControlButtonClicked'
      })
    }
  }

  private publishEvent(eventDetail: CdmEventDetail) {
    try {
      this.publishCdmEvents([
        {
          version: '1.0.0',
          dateTime: new Date().toISOString(),
          eventDetailType:
            'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
          eventCategory: 'simpleUi',
          eventDetail: {
            version: '1.0.0',
            activity: 'InstantInk-v01',
            screenPath: this.baseScreenPath,
            ...eventDetail
          }
        }
      ])
    } catch {
      // ignore error
    }
  }
}
