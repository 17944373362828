import { PartialSubscription } from '../../types'
import { inkPlansFixture } from './planFixtures'

export function partialSubscriptionFixture({
  id = 12345,
  flowName = 'v3_web',
  plan = inkPlansFixture()[0],
  printer = undefined,
  partialPrinter = undefined,
  isE2E = false,
  freeTrialMonths = 4,
  savingsPercent = { ink: '20%', toner: '40%' },
  daysBeforeIncentivesExpire = 7,
  promoCode = undefined,
  enrollmentKeyCode = undefined,
  referredByCode = undefined,
  prepaidKeyCodes = [],
  addons = [],
  totalPlanPriceAfterTrial = '$5.99',
  totalDueNow = '$0.00',
  afuAccepted = false,
  requireBilling = undefined,
  autoAppliedBalanceShortFormat = undefined,
  autoAppliedMonths = 0,
  userHasOtherSubscriptions = false,
  replacementEligibility = undefined,
  replacementData = undefined,
  instructionSet = 'set_2',
  genType = 'genY',
  isHpx = false,
  appName = '',
  oobePrinterClaimStatusPollingDelay = 1,
  oobePrinterClaimStatusPollingTimeout = 1
} = {}): PartialSubscription {
  return {
    id,
    flowName,
    plan,
    printer,
    partialPrinter,
    isE2E,
    freeTrialMonths,
    savingsPercent,
    daysBeforeIncentivesExpire,
    promoCode,
    enrollmentKeyCode,
    referredByCode,
    prepaidKeyCodes,
    addons,
    totalPlanPriceAfterTrial,
    totalDueNow,
    afuAccepted,
    requireBilling,
    autoAppliedBalanceShortFormat,
    autoAppliedMonths,
    userHasOtherSubscriptions,
    replacementData,
    replacementEligibility,
    instructionSet,
    genType,
    isHpx,
    appName,
    oobePrinterClaimStatusPollingDelay,
    oobePrinterClaimStatusPollingTimeout
  }
}
