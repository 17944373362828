import { requestStateData } from './requestStateData'
import { SignupState } from '../types'

export const createPreenrollment = () =>
  requestStateData(
    'preenrollment',
    ({
      signupSession: { instantInkService },
      partialSubscription,
      localization
    }: SignupState) =>
      instantInkService.createPreenrollment(
        partialSubscription?.data?.id,
        `${localization.language}_${localization.country.toUpperCase()}`
      )
  )
