import { AnalyticsClient, FlowType, SignupState } from '../../types'
import { UdlAnalyticsClient } from './udlAnalyticsClient'
import { CdmSmbAnalyticsClient } from './cdmSmbAnalyticsClient'
import { CombinedCdmAndUdlAnalyticsClient } from './combinedCdmAndUdlAnalyticsClient'

export const analyticsClientFactory = (
  signupState: SignupState
): AnalyticsClient => {
  const {
    signupSession: { flow, isODFlow, isSmbFlow }
  } = signupState
  const isOobeFlowFromOD =
    isODFlow &&
    (flow == FlowType.OOBE_VALUE_PROP || flow == FlowType.OOBE_ENROLL)

  if (isSmbFlow) {
    return new CdmSmbAnalyticsClient(signupState)
  }

  if (isOobeFlowFromOD) {
    return new CombinedCdmAndUdlAnalyticsClient(signupState)
  }

  return new UdlAnalyticsClient(signupState)
}
