import React, { FC } from 'react'
import * as Styled from './styles'

export const HpxCard: FC<{
  step?: string
  split?: boolean
  leftSection?: React.ReactNode
  rightSection?: React.ReactNode
}> = ({ step, split, leftSection, rightSection, children }) => {
  let padding: string

  switch (step) {
    case 'afu':
      padding = '32px'
      break
    case 'connect':
      padding = '48px 32px 32px'
      break
    default:
      padding = '56px 32px'
      break
  }

  padding = split ? '0' : padding

  if (split) {
    return (
      <Styled.HpxCard $padding={padding} $splitCard={split}>
        <Styled.ContentContainer>
          <Styled.LeftSection>{leftSection}</Styled.LeftSection>
          <Styled.RightSection>{rightSection}</Styled.RightSection>
        </Styled.ContentContainer>
      </Styled.HpxCard>
    )
  }

  return (
    <Styled.HpxCard $padding={padding} $splitCard={split}>
      {children}
    </Styled.HpxCard>
  )
}
