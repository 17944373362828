import {
  AnalyticsClient,
  PartialSubscription,
  SignupState,
  Subscription,
  UserAccount
} from '../../types'
import { loadScript, waitFor } from '../scriptHelpers'
import { toDashes } from '../stringHelpers'
type EventType =
  | 'e_pageView'
  | 'e_linkClick'
  | 'e_promotion'
  | 'e_promotionImpression'
  | 'e_couponApplied'

export interface UdlEvent {
  event: EventType
  linkID?: string
  linkPlacement?: string
  pageNameL5?: string
  pageNameL6?: string
  userID?: string
  cartID?: number
  deviceSKU?: string
  deviceID?: string
  loginStatus?: boolean
  v152?: string
  v153?: string
  v157?: string
  couponCode?: string
  offerID?: number
  subscriptionID?: string
  printerProgram?: string
  tenancy?: string
  appInstanceID?: string
  ecommerce?: {
    currencyCode?: string
    purchase?: {
      actionField?: {
        id?: string
        affiliation?: string
      }
      products?: {
        name?: string
        price?: string
        brand?: string
        quantity?: number
      }[]
    }
  }
}

export class UdlAnalyticsClient implements AnalyticsClient {
  pathname: string
  linkPlacement: string
  isLoggedIn: boolean
  loadUdlResult: Promise<void>
  userAccount?: UserAccount
  partialSubscription?: PartialSubscription
  subscription?: Subscription
  xCorrelationId?: string
  signupSession: SignupState['signupSession']

  constructor(signupState: SignupState) {
    const { isLoggedIn, signupSession, partialSubscription } = signupState
    this.pathname = ''
    this.linkPlacement = ''
    this.isLoggedIn = isLoggedIn
    this.loadUdlResult = this.loadUdl(signupSession.instantInkURL)
    this.partialSubscription = partialSubscription.data
    this.xCorrelationId = signupSession?.xCorrelationId
    this.signupSession = signupSession
  }

  updateState({ partialSubscription, subscription, userAccount }: SignupState) {
    this.partialSubscription = partialSubscription.data
    this.subscription = subscription.data
    this.userAccount = userAccount.data
  }

  publishClickEvent({ eventTarget }: { eventTarget?: EventTarget }) {
    const verifyAndPublishEvent = (element: Element) => {
      const nodeName = element.nodeName.toLowerCase()
      const role = element.getAttribute('role')
      const type = element.getAttribute('type')
      if (
        ['a', 'button'].includes(nodeName) ||
        role === 'button' ||
        ['checkbox', 'radio'].includes(type)
      ) {
        const linkID = toDashes(
          element.getAttribute('data-analyticsid') ||
            element.getAttribute('data-testid') ||
            element.textContent
        )
        const linkPlacement =
          element.getAttribute('data-analyticsplacement') ||
          element.getAttribute('data-linkid') ||
          this.linkPlacement
        const orderSummaryRedesignAdditionalOptions =
          linkPlacement === 'ii-ee-order-summary-page' ||
          linkPlacement === 'ii-order-summary-page-personalized-onboarding'
            ? {
                deviceSKU: this.getDeviceSKU(),
                subscriptionID: this.getSubscriptionId()
              }
            : {}
        if (linkID) {
          this.publishEvent({
            event: 'e_linkClick',
            linkID,
            linkPlacement,
            ...orderSummaryRedesignAdditionalOptions
          })
          return true
        }
      }
    }

    let currentElement = eventTarget instanceof Element ? eventTarget : null
    for (let i = 0; i < 5 && currentElement; i++) {
      if (verifyAndPublishEvent(currentElement)) {
        break
      }
      currentElement =
        currentElement.parentNode instanceof Element
          ? currentElement.parentNode
          : null
    }
  }

  publishDisplayEvent({
    pathname,
    screenName
  }: {
    pathname?: string
    screenName?: string
  }) {
    if (pathname) {
      this.pathname = pathname
    }
    if (screenName) {
      // prevent page view for modal
      return
    }
    const priceWithoutSignArray = (
      this.partialSubscription?.plan?.price || ''
    ).match(/\d.*/)
    const priceWithoutSign = priceWithoutSignArray
      ? priceWithoutSignArray[0]
      : null

    const thankYouAdditionalOptions =
      this.getPageName(this.getDefaultScreenName()) === 'thank-you'
        ? {
            subscriptionID: this.getSubscriptionId(),
            printerProgram: this.partialSubscription?.isE2E ? 'e2e' : 'flex',
            tenancy:
              this.userAccount?.type === 'BusinessTransactionalSMB'
                ? 'Business/SMB'
                : 'Personal',
            ecommerce: {
              currencyCode: this.partialSubscription?.plan?.currencyCode,
              purchase: {
                actionField: {
                  id: this.getSubscriptionId(),
                  affiliation: 'HP Online'
                },
                products: [
                  {
                    name: `${this.partialSubscription?.plan?.pages} pages`,
                    price: priceWithoutSign,
                    brand: 'HP',
                    quantity: 1
                  }
                ]
              }
            }
          }
        : {}
    const replacementThankYouAdditionalOptions =
      this.getPageName(this.getDefaultScreenName()) === 'replacement-thank-you'
        ? {
            subscriptionID: this.getSubscriptionId()
          }
        : {}

    const loginStatus = this.isLoggedIn ? 'logged in' : 'logged out'

    this.publishEvent({
      cartID: this.partialSubscription?.id,
      event: 'e_pageView',
      pageNameL5: 'web',
      pageNameL6: this.getPageName(this.getDefaultScreenName()),
      v152: this.xCorrelationId,
      v153: this.partialSubscription?.flowName,
      v157: loginStatus,
      couponCode: this.partialSubscription?.promoCode,
      offerID: this.partialSubscription?.freeTrialMonths,
      deviceSKU: this.getDeviceSKU(),
      ...thankYouAdditionalOptions,
      ...replacementThankYouAdditionalOptions
    })
  }

  publishCustomEvent(event?: UdlEvent) {
    if (!event) return
    this.publishEvent(event)
  }

  private requiredParams() {
    return {
      userID: this.userAccount?.resourceId,
      loginStatus: this.isLoggedIn,
      deviceID: this.getDeviceId(),
      appInstanceID: this.signupSession?.appInstanceID
    }
  }
  private getDefaultScreenName() {
    return this.pathname.substring(this.pathname.lastIndexOf('/') + 1)
  }

  private getPageName(screenName: string) {
    return (
      {
        ['printer-selection']: 'ii_printer_selection',
        ['enroll-printer']: 'ii_enroll',
        enroll: 'ii_enroll_no_printer',
        connect: 'ii_deeplink'
      }[screenName] || screenName
    )
  }

  private isUdlLoaded(): boolean {
    return typeof window['udlDataPusher'] === 'function'
  }

  private async loadUdl(instantInkUrl: string) {
    if (!this.isUdlLoaded()) {
      ;[
        ['udl-analytics-gtm', `${instantInkUrl}/api/scripts/analytics_gtm.js`],
        ['udl-marketing-gtm', `${instantInkUrl}/api/scripts/marketing_gtm.js`]
      ].forEach(([scriptId, scriptSrc]) => loadScript({ scriptId, scriptSrc }))

      await waitFor(
        async () => {
          if (this.isUdlLoaded()) return
          throw new Error('Time out waiting for UDL to load.')
        },
        100,
        60000
      )
    }
  }

  private publishEvent(udlEvent: UdlEvent) {
    ;(async () => {
      try {
        if (udlEvent.linkPlacement) {
          this.linkPlacement = udlEvent.linkPlacement
        }
        await this.loadUdlResult
        window['udlDataPusher']({
          ...this.requiredParams(),
          ...udlEvent
        })
      } catch {
        // ignore error
      }
    })()
  }

  private getDeviceId() {
    return (
      this.signupSession?.printerUuid ||
      this.partialSubscription?.printer?.deviceUuid ||
      this.partialSubscription?.partialPrinter?.deviceUuid
    )
  }

  private getDeviceSKU() {
    return (
      this.signupSession?.printerSku ||
      this.partialSubscription?.printer?.sku ||
      this.partialSubscription?.partialPrinter?.sku
    )
  }

  private getSubscriptionId() {
    return (
      this.subscription?.accountIdentifier ||
      this.signupSession?.accountIdentifier ||
      this.partialSubscription.replacementData?.printer?.accountIdentifier
    )
  }
}
