import styled, { css } from 'styled-components'
import { isDesktop } from '../../../../lib/screenHelpers'
import primitives from '@veneer/primitives'

export const HpxCard = styled.div<{ $padding: string; $splitCard?: boolean }>`
  && {
    ${({ theme: { isHpx, containerSize }, $padding, $splitCard }) => {
      if (isHpx) {
        return css`
          overflow: hidden;
          background-color: ${primitives.color.white};
          border-radius: 24px;
          padding: ${$padding};
          position: relative;
          z-index: 1;

          ${!isDesktop(containerSize) &&
          !$splitCard &&
          css`
            padding: 16px;
          `}
        `
      }
    }}
  }
`

export const ContentContainer = styled.div`
  && {
    display: flex;
    align-items: stretch;

    ${({ theme: { containerSize } }) =>
      !isDesktop(containerSize) &&
      css`
        flex-direction: column;
      `};
  }
`

export const LeftSection = styled.div`
  && {
    display: flex;
    width: 50%;
    min-width: 40%;
    background-color: #f5f6ff;
    justify-content: center;
    align-items: center;

    ${({ theme: { containerSize } }) =>
      !isDesktop(containerSize) &&
      css`
        width: 100%;
        padding-top: 32px;
      `};
  }
`

export const RightSection = styled.div`
  && {
    display: flex;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;
    padding: 32px;

    ${({ theme: { containerSize } }) =>
      !isDesktop(containerSize) &&
      css`
        width: 100%;
        padding: 32px 16px;
      `};
  }
`
