import { AnalyticsClient, SignupState } from '../../types'
import { toPascal } from '../stringHelpers'
import { screenPathHelper } from './helpers/cdm/parametersHelpers'

export interface CdmEventDetail {
  version?: string
  activity?: string
  screenPath?: string
  screenName: string
  controlName?: string
  controlDetail?: string
  screenMode?: string
  action:
    | 'ScreenDisplayed'
    | 'ControlButtonClicked'
    | 'ControlHyperLinkClicked'
    | 'ModalWindowDisplayed'
}

export interface CdmEvent {
  version: string
  dateTime: string
  eventDetailType: string
  eventCategory: string
  eventDetail: CdmEventDetail
}

export type PublishCdmEvents = (data: CdmEvent[]) => unknown

export class CdmOobeAnalyticsClient implements AnalyticsClient {
  pathname: string
  baseScreenPath: string
  publishCdmEvents: PublishCdmEvents
  signupState: SignupState

  constructor(signupState: SignupState) {
    const {
      analytics,
      signupSession: { analyticsBaseScreenPathName }
    } = signupState

    const baseScreenPath = analyticsBaseScreenPathName || '/MfeOobeEnroll/'
    this.signupState = signupState
    this.pathname = ''
    this.baseScreenPath = baseScreenPath
    this.publishCdmEvents = analytics?.publishCdmEvents
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateState(signupState: SignupState) {
    this.signupState = signupState
  }

  publishClickEvent({
    eventTarget,
    linkId,
    linkPlacement
  }: {
    eventTarget?: EventTarget
    linkId?: string
    linkPlacement?: string
  }) {
    if (eventTarget instanceof Element) {
      const element = eventTarget
      const nodeName = element.nodeName.toLowerCase()
      const role = element.getAttribute('role')
      const type = element.getAttribute('type')

      if (
        ['a', 'button'].includes(nodeName) ||
        role === 'button' ||
        ['checkbox', 'radio'].includes(type)
      ) {
        const screenName = toPascal(
          element['screenName'] || this.getDefaultScreenName()
        )
        const screenPath = element['screenName']
          ? this.getModalScreenPath()
          : this.getScreenPath(screenName)
        let controlName = toPascal(
          element.getAttribute('data-analyticsid') ||
            element.getAttribute('data-testid') ||
            element.textContent
        )
        const controlDetail = element['controlDetail']
        const action =
          nodeName === 'a' ? 'ControlHyperLinkClicked' : 'ControlButtonClicked'

        if (controlName) {
          controlName =
            controlName == 'CloseSymbolButton' ? 'CloseModal' : controlName
          this.publishEvent({
            screenPath,
            screenName,
            controlName,
            controlDetail,
            action
          })
        }
      }
      return
    }
    if (linkId || linkPlacement) this.publishLink(linkId, linkPlacement)
  }

  publishDisplayEvent({
    pathname,
    screenName,
    screenMode,
    action,
    screenPath
  }: {
    pathname?: string
    screenName?: string
    screenMode?: string
    action?: string
    screenPath?: string
  }) {
    if (pathname) {
      this.pathname = pathname + 'Step'
    }
    const newScreenName = toPascal(screenName || this.getDefaultScreenName())
    const newScreenPath = screenName
      ? this.getModalScreenPath()
      : this.getScreenPath(newScreenName)

    this.publishEvent({
      screenPath: screenPath || newScreenPath,
      screenName: newScreenName,
      action: action == 'ModalWindowDisplayed' ? action : 'ScreenDisplayed',
      ...(screenMode && { screenMode })
    })
  }

  publishCustomEvent() {
    return
  }

  private getDefaultScreenName() {
    return toPascal(this.pathname.substring(this.pathname.lastIndexOf('/') + 1))
  }

  private getScreenPath(screenName?: string) {
    return screenPathHelper[screenName] || this.baseScreenPath
  }

  private getModalScreenPath() {
    const screenName = this.getDefaultScreenName()
    return `${this.getScreenPath(screenName)}${
      screenName ? screenName + '/' : ''
    }`
  }

  private requiredParams() {
    return {
      userID: this.signupState.userAccount?.data?.regionId,
      deviceID: this.getDeviceId(),
      appInstanceID: this.signupState.signupSession?.appInstanceID,
      partialSubscriptionID: this.signupState.partialSubscription?.data.id,
      subscriptionID: this.signupState.subscription?.data?.accountIdentifier
    }
  }

  private getDeviceId() {
    return (
      this.signupState.signupSession?.printerUuid ||
      this.signupState.partialSubscription?.data?.printer?.deviceUuid ||
      this.signupState.partialSubscription?.data?.partialPrinter?.deviceUuid
    )
  }

  private publishLink(linkId?: string, linkPlacement?: string) {
    if (linkId && linkPlacement) {
      this.publishEvent({
        screenPath: this.baseScreenPath,
        screenName: toPascal(linkPlacement),
        controlName: linkId,
        action: 'ControlButtonClicked'
      })
    }
  }

  private publishEvent(eventDetail: CdmEventDetail) {
    try {
      this.publishCdmEvents([
        {
          version: '1.0.0',
          dateTime: new Date().toISOString(),
          eventDetailType:
            'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
          eventCategory: 'simpleUi',
          eventDetail: {
            version: '1.0.0',
            activity: 'InstantInk-v01',
            screenPath: this.baseScreenPath,
            ...eventDetail,
            ...this.requiredParams()
          }
        }
      ])
    } catch {
      // ignore error
    }
  }
}
