import { useCallback } from 'react'
import { updatePartialSubscription } from '../stateData/partialSubscription'
import { useDispatch } from './useDispatch'
import { useFetchPrinterClaimStatus } from './useFetchPrinterClaimStatus'
import { useSignupState } from './useSignupState'
import { useAutoClaimContext } from './useAutoClaimContext'
import PrinterClaimStatusPoller from '../lib/printerClaimStatusPoller'
import { AxiosError } from 'axios'
import { PartialSubscription } from '../types'

export const useFindAutoClaimPrinter = () => {
  const dispatch = useDispatch()
  const fetchPrinterClaimStatus = useFetchPrinterClaimStatus()
  const { partialSubscription } = useSignupState()
  const { setAutoClaimLoading, setAutoClaimFailed, setAutoClaimError } =
    useAutoClaimContext() ?? {}

  const {
    genType,
    printer,
    partialPrinter,
    oobePrinterClaimStatusPollingDelay,
    oobePrinterClaimStatusPollingTimeout
  } = partialSubscription.data

  const waitForAutoClaim = useCallback(async () => {
    if (genType === 'genY') {
      return
    }

    try {
      const printerClaimStatusPoller = new PrinterClaimStatusPoller({
        fetchPrinterClaimStatus,
        interval: oobePrinterClaimStatusPollingDelay || 10,
        timeout: oobePrinterClaimStatusPollingTimeout || 30
      })

      const { success, claimStatus } = await printerClaimStatusPoller.poll()
      if (success) {
        return
      }

      if (claimStatus.startsWith('autoClaimRetry')) {
        await dispatch(
          updatePartialSubscription({
            auto_claim_retry: claimStatus.split(':')[1]
          })
        )
        return
      }
    } catch {
      // do nothing
    }

    setAutoClaimFailed(true)
    throw Error('Auto Claim Failed!')
  }, [
    dispatch,
    fetchPrinterClaimStatus,
    genType,
    oobePrinterClaimStatusPollingDelay,
    oobePrinterClaimStatusPollingTimeout,
    setAutoClaimFailed
  ])

  const findAutoClaimPrinter = useCallback(async () => {
    try {
      const { printer, partialPrinter } = (await dispatch(
        updatePartialSubscription({ printer_id: 'autoclaim' })
      )) as PartialSubscription
      return printer || partialPrinter
    } catch (error) {
      const { error_code, error_id } =
        (error as AxiosError)?.response?.data ?? {}
      if (error_code) {
        setAutoClaimError({ code: error_code, id: error_id })
      } else {
        setAutoClaimError({ code: 'printer_error', id: 'GSS001' })
      }
      throw error
    }
  }, [dispatch, setAutoClaimError])

  return useCallback(async () => {
    if (printer || partialPrinter) {
      return printer || partialPrinter
    }

    try {
      setAutoClaimLoading?.call(null, true)
      await waitForAutoClaim()
      return await findAutoClaimPrinter()
    } finally {
      setAutoClaimLoading?.call(null, false)
    }
  }, [
    findAutoClaimPrinter,
    partialPrinter,
    printer,
    setAutoClaimLoading,
    waitForAutoClaim
  ])
}
