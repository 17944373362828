import { useCallback, useEffect, useState } from 'react'
import {
  getConsumableConfigDynHref,
  getSuppliesPublicHref
} from '../lib/printerResourceHelpers'
import { useDeviceHttpProxyContext, useDispatch, useSignupSession } from '.'
import { updateSignupSession } from '../actions'

const XML_PARSER = new DOMParser()

const CDM_DEVICE = 'cdmOnly'
// const LEDM_DEVICE = 'ledmOnly'
// const HYBRID_DEVICE = 'ledmAndCdm'

const getSupplyType = ({
  isTrial,
  isSetup
}: {
  isTrial: string
  isSetup: string
}) => {
  if (isTrial === 'true') {
    return 'trial'
  }
  return isSetup === 'true' ? 'host' : 'trade'
}

export const useSupplyTypes = () => {
  const { sendDHPRequest, appSessionId } = useDeviceHttpProxyContext()
  const dispatch = useDispatch()
  const {
    printerProtocolCapability,
    supplyTypes: sessionSupplyTypes
  } = useSignupSession()
  const [supplyTypes, setSupplyTypes] = useState([])
  const [done, setDone] = useState(false)

  const fetchResource = useCallback(
    async (
      href: string,
      method = 'GET'
    ): Promise<Record<string, unknown> | Document | undefined> => {
      const response = await sendDHPRequest({
        sessionId: appSessionId,
        path: href,
        method
      })
      if (response?.body?.data) {
        const isString = typeof response.body.data === 'string'
        const isJson = response.body?.contentType.indexOf('json') > -1
        const isXML = response.body?.contentType.indexOf('xml') > -1

        if (isString) {
          if (response.body?.isBase64Encoded) {
            response.body.data = atob(response.body.data)
          }

          if (isJson) {
            return JSON.parse(response.body.data)
          } else if (isXML) {
            return XML_PARSER.parseFromString(response.body.data, 'text/xml')
          }
        }
      }
      return undefined
    },
    [sendDHPRequest, appSessionId]
  )

  const fetchServicesDiscovery = useCallback(() => {
    /* NOTE: For hybrid devices, different trees may be supported for each printer. Will want to be more dynamic in future */
    const resource =
      printerProtocolCapability === CDM_DEVICE
        ? '/cdm/servicesDiscovery'
        : '/DevMgmt/DiscoveryTree.xml'
    return fetchResource(resource)
  }, [fetchResource, printerProtocolCapability])

  const getCdmSupplyTypes = useCallback(async (): Promise<string[]> => {
    const servicesDiscovery = await fetchServicesDiscovery()
    const response = await fetchResource(
      getSuppliesPublicHref(servicesDiscovery)
    )
    return response['suppliesList']
      .filter(
        ({ isTrial, isSetup }) => isTrial !== undefined || isSetup !== undefined
      )
      .map(getSupplyType)
  }, [fetchServicesDiscovery, fetchResource])

  const getLedmSupplyTypes = useCallback(async (): Promise<string[]> => {
    const servicesDiscovery = await fetchServicesDiscovery()
    const response = (await fetchResource(
      getConsumableConfigDynHref(servicesDiscovery)
    )) as Document
    return Array.from(response.getElementsByTagName('dd:ConsumableLifeState'))
      .map((supply) => ({
        isTrial: supply.getElementsByTagName('dd:IsTrial')[0]?.textContent,
        isSetup: supply.getElementsByTagName('dd:IsSETUP')[0]?.textContent
      }))
      .filter(
        ({ isTrial, isSetup }) => isTrial !== undefined || isSetup !== undefined
      )
      .map(getSupplyType)
  }, [fetchServicesDiscovery, fetchResource])

  const getSupplyTypes = useCallback(
    async () =>
      printerProtocolCapability === CDM_DEVICE
        ? getCdmSupplyTypes()
        : getLedmSupplyTypes(),
    [getCdmSupplyTypes, getLedmSupplyTypes, printerProtocolCapability]
  )

  useEffect(() => {
    if (!done) {
      if (sessionSupplyTypes) {
        setSupplyTypes(sessionSupplyTypes.split(','))
        setDone(true)
      } else {
        if (appSessionId) {
          ;(async () => {
            try {
              const supplies = await getSupplyTypes()
              await dispatch(
                updateSignupSession({ supplyTypes: supplies.join(',') })
              )
              setSupplyTypes(supplies)
            } catch (e) {
              console.warn('Failed to fetch supply types', e)
            }
            setDone(true)
          })()
        } else {
          setDone(true)
        }
      }
    }
  }, [
    appSessionId,
    dispatch,
    done,
    getSupplyTypes,
    sessionSupplyTypes,
    supplyTypes
  ])

  return done ? supplyTypes.join(',') : undefined
}
