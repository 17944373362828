import React, { useContext, useEffect, useRef, useState } from 'react'
import ProgressIndicator, {
  ProgressIndicatorInterface
} from '@veneer/core/dist/scripts/progress_indicator'
import { MfeComponentContext } from './types'
import * as S from './styles'

export const ComponentLoader = () => {
  const { name, props } = useContext(MfeComponentContext)
  const [component, setComponent] = useState<React.ReactElement | null>(null)
  const componentRef = useRef(null)
  const [ready, setReady] = useState(false)
  const { componentName } = props || {}
  const loadingAppearance = props?.loadingAppearance || 'none'

  useEffect(() => {
    if (!ready) {
      ;(async () => {
        switch (name) {
          case 'react-billing': {
            const { BillingForm } = await import(
              /* webpackChunkName: "jarvis-react-billing" */ `@jarvis/react-billing`
            )
            componentRef.current = BillingForm
            break
          }
          case 'react-shipping': {
            const { ShippingForm } = await import(
              /* webpackChunkName: "jarvis-react-shipping" */ '@jarvis/react-shipping'
            )
            componentRef.current = ShippingForm
            break
          }
          case 'react-instant-ink-plans': {
            const {
              PlanSelector,
              ConsumerPlanSelector,
              PlanSelectorV3
            } = await import(
              /* webpackChunkName: "jarvis-react-instant-ink-plans" */ '@jarvis/react-instant-ink-plans'
            )
            if (componentName === 'ConsumerPlanSelector') {
              componentRef.current = ConsumerPlanSelector
            } else if (componentName === 'PlanSelectorV3') {
              componentRef.current = PlanSelectorV3
            } else {
              componentRef.current = PlanSelector
            }
            break
          }
          case 'react-manual-printer-claim': {
            const { ManualPrinterClaim } = await import(
              /* webpackChunkName: "jarvis-react-manual-printer-claim" */ '@jarvis/react-manual-printer-claim'
            )
            // do not re-render MPC component
            setComponent(<ManualPrinterClaim {...props} />)
            break
          }
          case 'react-instant-ink-country-selector': {
            const {
              CountrySelector,
              GapHandler,
              LanguageSelector
            } = await import(
              /* webpackChunkName: "jarvis-react-instant-ink-country-selector" */ '@jarvis/react-instant-ink-country-selector'
            )
            if (componentName === 'CountrySelector') {
              componentRef.current = CountrySelector
            } else if (componentName === 'GapHandler') {
              componentRef.current = GapHandler
            } else if (componentName === 'LanguageSelector') {
              componentRef.current = LanguageSelector
            } else {
              setComponent(<div>Component {componentName} not found.</div>)
            }
            break
          }
          default: {
            setComponent(<div>Invalid Component</div>)
            break
          }
        }
        setReady(true)
      })()
    }
  }, [ready, name, componentName, props])

  if (!ready && loadingAppearance !== 'none') {
    return (
      <S.LoadingContainer>
        <ProgressIndicator
          appearance={
            loadingAppearance as ProgressIndicatorInterface['appearance']
          }
        />
      </S.LoadingContainer>
    )
  }

  if (ready) {
    return component || <componentRef.current {...props} />
  }

  return null
}
