import {
  SignupAction,
  SignupActionType,
  SignupSession,
  SignupState,
  Subscription
} from '../types'
import { AsyncDispatch } from '../hooks/useAsyncReducer'
import {
  createPartialSubscription,
  fetchPartialSubscription,
  createSubscription,
  convertFlipSubscription,
  replacePrinter
} from '../stateData/actions'

export const startSignupSession = ({
  partialSubscriptionId,
  printerUuid,
  printerBizModel,
  printerSku,
  flowType,
  initiator,
  accountIdentifier,
  printerIdParam
}: Partial<SignupSession> & { flowType?: string }) => async (
  dispatch: AsyncDispatch<SignupAction, SignupState>
) => {
  await dispatch({
    type: SignupActionType.START_SIGNUP_SESSION
  })

  const partialSubscription = await dispatch(
    partialSubscriptionId
      ? fetchPartialSubscription(partialSubscriptionId)
      : createPartialSubscription(
          printerUuid,
          printerBizModel,
          printerSku,
          flowType
        )
  )

  await dispatch(
    updateSignupSession({
      partialSubscriptionId: partialSubscription['id'],
      isHpx: partialSubscription['isHpx'],
      ...(initiator ? { initiator } : {}),
      ...(printerIdParam ? { printerIdParam } : {}),
      ...(accountIdentifier ? { accountIdentifier } : {})
    })
  )

  return partialSubscription
}

export const updateSignupSession = (
  newValues: Record<string, unknown>
) => async (dispatch: AsyncDispatch<SignupAction, SignupState>) => {
  await dispatch({
    type: SignupActionType.UPDATE_SIGNUP_SESSION,
    signupSession: newValues
  })
}

export const completeEnrollmentSignupSession = () => async (
  dispatch: AsyncDispatch<SignupAction, SignupState>
) => {
  const { accountIdentifier } = (await dispatch(
    createSubscription()
  )) as Subscription

  await dispatch(updateSignupSession({ accountIdentifier }))
}

export const completeConversionSignupSession = () => async (
  dispatch: AsyncDispatch<SignupAction, SignupState>
) => {
  await dispatch(convertFlipSubscription())

  await dispatch(
    updateSignupSession({
      flipConverted: true
    })
  )
}

export const completeReplacementSignupSession = () => async (
  dispatch: AsyncDispatch<SignupAction, SignupState>
) => {
  const { accountIdentifier } = (await dispatch(
    replacePrinter()
  )) as Subscription

  await dispatch(
    updateSignupSession({
      printerReplaced: true,
      accountIdentifier
    })
  )
}

export const exitSignupSession = () => async (
  dispatch: AsyncDispatch<SignupAction, SignupState>
) =>
  dispatch(
    updateSignupSession({
      accountIdentifier: undefined,
      partialSubscriptionId: undefined,
      initiator: undefined,
      currentStep: undefined,
      printerUuid: undefined,
      printerBizModel: undefined,
      printerSerialNumber: undefined,
      printerSku: undefined,
      printerDisplayName: undefined,
      printerImageUrl: undefined,
      showShippingModal: undefined,
      forcePlanModal: undefined,
      flipConverted: undefined,
      appInstanceID: undefined
    })
  )
