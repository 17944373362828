import { AnalyticsClient, SignupState } from '../../types'
import { UdlAnalyticsClient, UdlEvent } from './udlAnalyticsClient'
import { CdmOobeAnalyticsClient } from './cdmOobeAnalyticsClient'

export class CombinedCdmAndUdlAnalyticsClient implements AnalyticsClient {
  private cdmClient: CdmOobeAnalyticsClient
  private udlClient: UdlAnalyticsClient

  constructor(signupState: SignupState) {
    this.cdmClient = new CdmOobeAnalyticsClient(signupState)
    this.udlClient = new UdlAnalyticsClient(signupState)
  }
  pathname: string

  updateState(signupState: SignupState) {
    this.cdmClient.updateState(signupState)
    this.udlClient.updateState(signupState)
  }

  publishClickEvent(params: {
    eventTarget?: EventTarget
    linkId?: string
    linkPlacement?: string
  }) {
    this.cdmClient.publishClickEvent(params)
    this.udlClient.publishClickEvent(params)
  }

  publishDisplayEvent(params: { pathname?: string; screenName?: string }) {
    this.cdmClient.publishDisplayEvent(params)
    this.udlClient.publishDisplayEvent(params)
  }

  publishCustomEvent(event?: UdlEvent) {
    this.cdmClient.publishCustomEvent()
    this.udlClient.publishCustomEvent(event)
  }
}
