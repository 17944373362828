import { AxiosResponse } from 'axios'
import { JarvisWebHttpClient } from '@jarvis/web-http/dist/jarvis-web-http-client'
import {
  AuthProviderType,
  PartialSubscription,
  Plan,
  CodeType,
  Printer,
  Subscription,
  Settings,
  Benefits,
  Paths,
  DeeplinkInfo,
  Validations,
  Banner,
  PrinterOffer,
  PrinterBenefits,
  ConnectAndVerifyType,
  AddressResponse
} from '../types'

export interface IInstantInkService {
  fetchSettings(
    language?: string,
    country?: string
  ): Promise<AxiosResponse<Settings>>

  fetchPaths(language?: string, country?: string): Promise<AxiosResponse<Paths>>

  fetchDeeplinkInfo(): Promise<AxiosResponse<DeeplinkInfo>>

  createPartialSubscription(
    printerUuid: string,
    printerBizModel: string,
    printerSku: string,
    flowType: string
  ): Promise<AxiosResponse<PartialSubscription>>

  fetchPartialSubscription(
    id: string | number,
    language?: string,
    country?: string
  ): Promise<AxiosResponse<PartialSubscription>>

  updatePartialSubscription(
    id: number,
    params: {
      plan_id?: number
      sign_up_completion_code?: string
      printer_id?: number | string
      special_offers_code?: string
      paper_opt_in?: boolean
      afu_accepted?: boolean
      auto_claim_retry?: string
      register_printer?: boolean
      claim_printer?: { code: string; confirm_transfer?: boolean }
    }
  ): Promise<AxiosResponse<PartialSubscription>>

  createPreenrollment(
    partialSubscriptionId: number,
    preferredLocale?: string
  ): Promise<AxiosResponse<void>>

  fetchPlans(
    partialSubscriptionId: number,
    language?: string,
    country?: string
  ): Promise<AxiosResponse<Plan[]>>

  fetchBanner(
    partialSubscriptionId: number,
    language?: string,
    country?: string
  ): Promise<AxiosResponse<Banner>>

  fetchCodeType(
    partialSubscriptionId: number,
    code: string
  ): Promise<AxiosResponse<CodeType>>

  fetchBenefits(partialSubscriptionId: number): Promise<AxiosResponse<Benefits>>

  fetchPrinters(): Promise<AxiosResponse<Printer[]>>

  fetchPrinterBenefits(
    deviceUuid: string
  ): Promise<AxiosResponse<PrinterBenefits>>

  createSubscription(
    partialSubscriptionId: number,
    preferredLocale?: string
  ): Promise<AxiosResponse<Subscription>>

  convertFlipSubscription(
    partialSubscriptionId: number,
    accountIdentifier: string,
    preferredLocale?: string
  ): Promise<AxiosResponse<Subscription>>

  replacePrinter(
    partialSubscriptionId: number
  ): Promise<AxiosResponse<Subscription>>

  fetchValidations(
    partialSubscriptionId: number
  ): Promise<AxiosResponse<Validations>>

  fetchPrinterOffer(
    serialNumber: string,
    productNumber: string,
    bizModel: string,
    supplyTypes: string,
    createStandardOobeRegistration: boolean,
    country: string,
    flow: string,
    partialSubscriptionId?: number
  ): Promise<AxiosResponse<PrinterOffer>>

  connectAndVerify(
    partialSubscriptionId: number
  ): Promise<AxiosResponse<ConnectAndVerifyType>>

  updateEnrollTracker(
    partialSubscriptionId: number,
    params: {
      terms_consent?: boolean
    }
  ): Promise<AxiosResponse>

  fetchPrinterClaimStatus(
    partialSubscriptionId: number
  ): Promise<AxiosResponse<string>>

  fetchAutofillAddresses(
    param: string,
    partialSubscriptionId: number
  ): Promise<AxiosResponse<AddressResponse>>
}

export class InstantInkService implements IInstantInkService {
  httpClient: JarvisWebHttpClient
  xCorrelationId: string
  apiName = 'mfe'
  apiVersion = '1'
  settingsEndpoint = '/settings'
  pathsEndpoint = '/paths'
  deeplinkEndpoint = '/deeplink'
  partialSubscriptionEndpoint = '/partial_subscriptions'
  preenrollmentsEndpoint = '/preenrollments'
  subscriptionEndpoint = '/subscriptions'
  printersEndpoint = '/printers'
  printerOffersEndpoint = '/printer_offers'
  printerBenefitsEndpoint = '/printer_benefits'
  printerClaimEndpoint = '/printer_claims'

  constructor(
    baseURL: string,
    xCorrelationId: string,
    authProvider?: AuthProviderType
  ) {
    this.httpClient = new JarvisWebHttpClient({
      defaultBaseURL: `${baseURL}/api/${this.apiName}/v${this.apiVersion}`,
      defaultAuthProvider: authProvider
    })
    this.xCorrelationId = xCorrelationId
  }

  fetchSettings(language?: string, country?: string): Promise<AxiosResponse> {
    return this.httpClient.get({
      ...this.requestOptions(),
      url: this.settingsEndpoint,
      params: this.localeParams(language, country)
    })
  }

  fetchPaths(language?: string, country?: string): Promise<AxiosResponse> {
    return this.httpClient.get({
      ...this.requestOptions(),
      url: this.pathsEndpoint,
      params: this.localeParams(language, country)
    })
  }

  fetchDeeplinkInfo(): Promise<AxiosResponse> {
    return this.httpClient.get({
      ...this.requestOptions(),
      url: `${this.deeplinkEndpoint}?origin=enrollment`
    })
  }

  createPartialSubscription(
    printerUuid: string,
    printerBizModel: string,
    printerSku: string,
    flowType: string
  ): Promise<AxiosResponse<PartialSubscription>> {
    return this.httpClient.post({
      ...this.requestOptions(),
      url: this.partialSubscriptionEndpoint,
      data: {
        printerUuid,
        printerBizModel,
        printerSku,
        flowType
      }
    })
  }

  fetchPartialSubscription(
    id: number,
    language?: string,
    country?: string
  ): Promise<AxiosResponse<PartialSubscription>> {
    return this.httpClient.get({
      ...this.requestOptions(),
      url: `${this.partialSubscriptionEndpoint}/${id}`,
      params: this.localeParams(language, country)
    })
  }

  updatePartialSubscription(
    id: number,
    params: {
      plan_id?: number
      sign_up_completion_code?: string
      printer_id?: number
      special_offers_code?: string
      paper_opt_in?: boolean
      afu_accepted?: boolean
      auto_claim_retry?: string
      register_printer?: boolean
      claim_printer?: { code: string; confirm_transfer?: boolean }
    }
  ): Promise<AxiosResponse<PartialSubscription>> {
    return this.httpClient.put({
      ...this.requestOptions(),
      url: `${this.partialSubscriptionEndpoint}/${id}`,
      data: JSON.parse(JSON.stringify(params))
    })
  }

  createPreenrollment(
    partialSubscriptionId: number,
    preferredLocale: string
  ): Promise<AxiosResponse<void>> {
    return this.httpClient.post({
      ...this.requestOptions(),
      url: `${this.preenrollmentsEndpoint}`,
      data: {
        partial_subscription_id: partialSubscriptionId,
        preferredLocale
      }
    })
  }

  fetchBenefits(
    partialSubscriptionId: number
  ): Promise<AxiosResponse<Benefits>> {
    return this.httpClient.get({
      ...this.requestOptions(),
      url: `${this.partialSubscriptionEndpoint}/${partialSubscriptionId}/enrollment_benefits`
    })
  }

  fetchPlans(
    partialSubscriptionId: number,
    language?: string,
    country?: string
  ): Promise<AxiosResponse<Plan[]>> {
    return this.httpClient.get({
      ...this.requestOptions(),
      url: `${this.partialSubscriptionEndpoint}/${partialSubscriptionId}/plans`,
      params: this.localeParams(language, country)
    })
  }

  fetchBanner(
    partialSubscriptionId: number,
    language?: string,
    country?: string
  ): Promise<AxiosResponse<Banner>> {
    return this.httpClient.get({
      ...this.requestOptions(),
      url: `${this.partialSubscriptionEndpoint}/${partialSubscriptionId}/banners`,
      params: this.localeParams(language, country)
    })
  }

  fetchCodeType(
    partialSubscriptionId: number,
    code: string
  ): Promise<AxiosResponse<CodeType>> {
    return this.httpClient.get({
      ...this.requestOptions(),
      url: `${this.partialSubscriptionEndpoint}/${partialSubscriptionId}/codes/${code}`
    })
  }

  fetchPrinters(): Promise<AxiosResponse<Printer[]>> {
    return this.httpClient.get({
      ...this.requestOptions(),
      url: `${this.printersEndpoint}`,
      timeout: 100_000
    })
  }

  fetchPrinterBenefits(
    deviceUuid: string
  ): Promise<AxiosResponse<PrinterBenefits>> {
    return this.httpClient.get({
      ...this.requestOptions(),
      url: `${this.printerBenefitsEndpoint}/${deviceUuid}`
    })
  }

  createSubscription(
    partialSubscriptionId: number,
    preferredLocale: string
  ): Promise<AxiosResponse<Subscription>> {
    return this.httpClient.post({
      ...this.requestOptions(),
      url: this.subscriptionEndpoint,
      data: {
        partialSubscriptionId,
        preferredLocale
      }
    })
  }

  convertFlipSubscription(
    partialSubscriptionId: number,
    accountIdentifier: string,
    preferredLocale: string
  ): Promise<AxiosResponse<Subscription>> {
    return this.httpClient.put({
      ...this.requestOptions(),
      url: `${this.subscriptionEndpoint}/flip_conversion`,
      data: {
        partial_subscription_id: partialSubscriptionId,
        account_identifier: accountIdentifier,
        preferredLocale
      }
    })
  }

  replacePrinter(
    partialSubscriptionId: number
  ): Promise<AxiosResponse<Subscription>> {
    return this.httpClient.patch({
      ...this.requestOptions(),
      url: `${this.partialSubscriptionEndpoint}/${partialSubscriptionId}/subscription/replace_printer`
    })
  }

  fetchValidations(
    partialSubscriptionId: number
  ): Promise<AxiosResponse<Validations>> {
    return this.httpClient.get({
      ...this.requestOptions(),
      url: `${this.partialSubscriptionEndpoint}/${partialSubscriptionId}/validate`
    })
  }

  fetchPrinterOffer(
    serialNumber: string,
    productNumber: string,
    bizModel: string,
    supplyTypes: string,
    createStandardOobeRegistration: boolean,
    country: string,
    flow: string,
    partialSubscriptionId?: number
  ): Promise<AxiosResponse<PrinterOffer>> {
    return this.httpClient.get({
      ...this.requestOptions(),
      url: `${this.printerOffersEndpoint}`,
      params: {
        serial_number: serialNumber,
        product_number: productNumber,
        biz_model: bizModel,
        supply_types: supplyTypes,
        create_standard_oobe_registration: createStandardOobeRegistration,
        country,
        flow,
        partial_subscription_id: partialSubscriptionId
      }
    })
  }

  connectAndVerify(
    partialSubscriptionId: number
  ): Promise<AxiosResponse<ConnectAndVerifyType>> {
    return this.httpClient.patch({
      ...this.requestOptions(),
      url: `${this.partialSubscriptionEndpoint}/${partialSubscriptionId}/connect_and_verify`
    })
  }

  updateEnrollTracker(
    partialSubscriptionId: number,
    params: { terms_consent?: boolean }
  ): Promise<AxiosResponse> {
    return this.httpClient.patch({
      ...this.requestOptions(),
      url: `${this.partialSubscriptionEndpoint}/${partialSubscriptionId}/enroll_tracker`,
      data: JSON.parse(JSON.stringify(params))
    })
  }

  fetchPrinterClaimStatus(
    partialSubscriptionId: number
  ): Promise<AxiosResponse<string>> {
    return this.httpClient.get({
      ...this.requestOptions(),
      url: `${this.printerClaimEndpoint}/status`,
      params: {
        partial_subscription_id: partialSubscriptionId
      }
    })
  }

  fetchAutofillAddresses(
    param: string,
    partialSubscriptionId: number
  ): Promise<AxiosResponse<AddressResponse>> {
    return this.httpClient.get({
      ...this.requestOptions(),
      url: `${this.partialSubscriptionEndpoint}/${partialSubscriptionId}/address_auto_complete`,
      params: {
        address: param
      }
    })
  }

  private requestOptions() {
    return {
      headers: {
        'X-Correlation-ID': this.xCorrelationId
      },
      validateStatus: (status: number) => status >= 200 && status < 300
    }
  }

  private localeParams(language?: string, country?: string) {
    return language && country
      ? {
          locale: `${country}/${language}`
        }
      : undefined
  }
}
