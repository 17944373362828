import React from 'react'
import Lottie from 'lottie-react'
import lottieSpinner from './lottieSpinner.json'
import * as Styled from './styles'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import { useSignupSession } from '../../../hooks'

export const LoadingPage = () => {
  const { isHpx } = useSignupSession()

  if (isHpx) {
    return (
      <Styled.Container data-testid="hpx-loading-page">
        <div>
          <Lottie animationData={lottieSpinner} />
        </div>
      </Styled.Container>
    )
  }

  return (
    <Styled.Container data-testid="progress-indicator-container">
      <ProgressIndicator />
    </Styled.Container>
  )
}
